import '../css/layout.scss';

// Create global $ and jQuery variables
import 'jquery';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).$ = $;

// Import bootstrap JS
import 'bootstrap';

// General layout JS
import '@drenso/layout-shared';

// Hide scrollbar in side menu plugin
import 'overlayscrollbars/js/jquery.overlayScrollbars';

// Font awesome icons
import '@fortawesome/fontawesome-free/js/regular.min';
import '@fortawesome/fontawesome-free/js/solid.min';
import '@fortawesome/fontawesome-free/js/fontawesome.min';
